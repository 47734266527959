import React, { useEffect, useState } from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const IndexPage = () => {
  const [pluginImage, setPluginImage] = useState<BgImage | null>(null);
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: {
            eq: "stock-photo-new-energy-solar-energy-in-sunny-day-1265049448.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              webpOptions: { quality: 70 }
              quality: 50
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
      }
    `
  );

  useEffect(() => {
    console.log(getImage(placeholderImage));
    setPluginImage(getImage(placeholderImage));
  }, [placeholderImage]);

  return (
    <Layout bgImage={pluginImage}>
      <Seo title="Virtual Sun" />
      <h1 className="inline-block align-middle">Καλώς ήλθατε</h1>
    </Layout>
  );
};

export const Head = () => <Seo title="Virtual Sun" />;

export default IndexPage;
